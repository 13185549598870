import React, { useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import axios from "axios";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Cta from "../components/cta";
import Face from "../images/face.svg";
import Enable from "../images/enable.svg";
import Chart from "../images/chart.svg";
import Likert from "../images/likert.svg";
import Check from "../images/check.svg";
import Circle from "../images/circle.svg";
import {
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";

const IndexPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState();

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setProcessing(true);
    const token = await executeRecaptcha("salesForm");
    const form = e.target;
    const data = new FormData(form);
    data.append("g-recaptcha-response", token);
    data.append("source", "sales-form");
    axios({
      method: "post",
      url: process.env.GATSBY_CONTACT_URL,
      headers: { "Content-Type": "multipart/form-data" },
      data,
    })
      .then((r) => {
        setProcessing(false);
        if (typeof window !== "undefined") {
          window.location = "/success/";
        }
      })
      .catch((r) => {
        setError("An error occurred, please try again later");
        setProcessing(false);
      });
  };

  return (
    <Layout>
      <Seo
        title="Performance Management Software"
        description="Employee performance review and 1:1 human resources software with powerful aggregated employee and team data designed to improve company outcomes."
      />
      <div className="container">
        <div className="row">
          <div className="hero-home">
            <div className="hero-home-text">
              {/* <span 
							className="highlight"
							data-sal="slide-up" 
							data-sal-easing="ease"
							data-sal-delay="100"
							data-sal-duration="300">
								Dramatically
						</span> */}
              <h1
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="300"
                data-sal-duration="400"
              >
                Change the way you think about performance management
              </h1>
              <p
                className="lead"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="400"
                data-sal-duration="400"
              >
                Use frontline data, competency mapping, performance tracking and
                talent management to supercharge your sales organisation.
              </p>
              <Link
                to="/contact"
                className="btn btn-lg btn-blue"
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-delay="500"
                data-sal-duration="400"
              >
                Request a demo
              </Link>
            </div>
            <div className="hero-home-img-wrapper">
              <StaticImage
                src="../images/hr-manager.png"
                formats={["AUTO", "WEBP", "AVIF"]}
                placeholder="none"
                className="hero-home-img"
                backgroundcolor="white"
                layout="fullWidth"
                quality="100%"
                alt="HR Manager at a laptop"
              />
              <div className="face">
                <img
                  src={Face}
                  alt="icon from a likert scale with a smiley face"
                />
              </div>
              <div className="enable">
                <img
                  src={Enable}
                  alt="icon from an enablement suggestion question"
                />
              </div>
              <div className="likert">
                <img src={Likert} alt="icon from a likert scale HR question" />
              </div>
              <div className="chart">
                <img
                  src={Chart}
                  alt="chart showing KPI Management performance"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section-pad-bottom" id="features">
        <div className="container-lg">
          <div className="row">
            <div className="feature-bg blue">
              <div className="container">
                <div className="row">
                  <div className="feature">
                    <div className="feature-img-wrapper">
                      <StaticImage
                        src="../images/enabli-charts.png"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        placeholder="none"
                        backgroundcolor="white"
                        className="feature-img"
                        layout="fullWidth"
                        quality="100%"
                        alt="laptop with the Enabli team performance charts dashboard"
                      />
                      <div className="circle">
                        <img src={Circle} alt="circle for decoration" />
                      </div>
                    </div>
                    <div className="feature-text">
                      {/* <span className="highlight"
											data-sal="slide-up" 
											data-sal-easing="ease"
											data-sal-delay="100"
											data-sal-duration="300"
										>Deep insights</span> */}
                      <h2
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="300"
                        data-sal-duration="400"
                      >
                        Aggregate frontline data
                      </h2>
                      <p
                        className="lead"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="400"
                        data-sal-duration="400"
                      >
                        Utilise frontline information to give a birds eye view
                        on competency, performance and general management.
                      </p>
                      <ul
                        className="list-unstyled feature-list"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="500"
                        data-sal-duration="400"
                      >
                        <li>
                          <img src={Check} alt="check icon" />
                          Aggregate competency information on a team or company
                          wide level
                        </li>
                        <li>
                          <img src={Check} alt="check icon" />
                          Gather market insight from the frontline salesforce
                        </li>
                        <li>
                          <img src={Check} alt="check icon" />
                          Understand individual and team performance
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-lg">
          <div className="row">
            <div className="feature-bg">
              <div className="container">
                <div className="row">
                  <div className="feature feature-mid">
                    <div className="feature-text">
                      {/* <span className="highlight"
											data-sal="slide-up" 
											data-sal-easing="ease"
											data-sal-delay="100"
											data-sal-duration="300"
										>Flexible</span> */}
                      <h3
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="300"
                        data-sal-duration="400"
                      >
                        Customise tracking metrics using performance reviews
                        &amp; one to one’s
                      </h3>
                      <p
                        className="lead"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="400"
                        data-sal-duration="400"
                      >
                        Using free type and likert scale reviews to track data
                        that is important to you.
                      </p>
                      <ul
                        className="list-unstyled feature-list"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="500"
                        data-sal-duration="400"
                      >
                        <li>
                          <img src={Check} alt="check icon" />
                          Use Enabli’s suggested tracking metrics put together
                          by Sales Leaders &amp; Management Consultants
                        </li>
                        <li>
                          <img src={Check} alt="check icon" />
                          Create your own tracking metrics unique to your
                          business
                        </li>
                      </ul>
                    </div>
                    <div className="feature-img-wrapper">
                      <StaticImage
                        src="../images/enabli-question-library.png"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        placeholder="none"
                        backgroundcolor="white"
                        className="feature-img"
                        layout="fullWidth"
                        quality="100%"
                        alt="laptop showing the enabli review question library"
                      />
                      <div className="circle-mid">
                        <img src={Circle} alt="circle for decoration" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-pad">
        <div className="container-lg">
          <div className="row">
            <div className="feature-bg purple">
              <div className="container">
                <div className="row">
                  <div className="feature">
                    <div className="feature-img-wrapper">
                      <StaticImage
                        src="../images/enabli-employee-reviews.png"
                        formats={["AUTO", "WEBP", "AVIF"]}
                        placeholder="none"
                        backgroundcolor="white"
                        className="feature-img"
                        layout="fullWidth"
                        quality="100%"
                        alt="laptop showing the enabli employee reviews screen"
                      />
                      <div className="circle">
                        <img src={Circle} alt="circle for decoration" />
                      </div>
                    </div>
                    <div className="feature-text">
                      {/* <span className="highlight"
											data-sal="slide-up" 
											data-sal-easing="ease"
											data-sal-delay="100"
											data-sal-duration="300"
										>Powerful</span> */}
                      <h3
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="300"
                        data-sal-duration="400"
                      >
                        Gain visibility
                      </h3>
                      <p
                        className="lead"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="400"
                        data-sal-duration="400"
                      >
                        All of your company performance data in one easy-to-use
                        dashboard.
                      </p>
                      <ul
                        className="list-unstyled feature-list"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="500"
                        data-sal-duration="400"
                      >
                        <li>
                          <img src={Check} alt="check icon" />
                          View employee performance and goals
                        </li>
                        <li>
                          <img src={Check} alt="check icon" />
                          Retain review and one to one data
                        </li>
                        <li>
                          <img src={Check} alt="check icon" />
                          Get better insight into your organisation
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="pricing" className="section-pad-bottom">
        <div className="container">
          <div className="row">
            <div className="pricing-row">
              <div className="pricing-tier">
                <div className="pricing-tier-grid">
                  <div className="pricing-tier-content">
                    <h4 className="price">Pricing</h4>
                    <p>
                      <strong>Tailored solution, transparent pricing:</strong>
                    </p>
                    <p>
                      Let's create a proposal that perfectly fits your team's
                      needs. Enabli's pricing depends on your specific setup, so
                      a quick chat will help us give you an accurate quote. Get
                      started by filling out the short form on the right!
                    </p>
                    <p>
                      <strong>Have questions?</strong>
                      <br /> Find out more on our <Link to="/faqs">
                        FAQs
                      </Link>{" "}
                      page or <Link to="/contact">contact us</Link>.
                    </p>
                  </div>
                  <form
                    className="pricing-tier-form"
                    method="post"
                    name="sales"
                    id="sales"
                    onSubmit={handleOnSubmit}
                  >
                    <div className="form-row">
                      <div className="form-item w-100">
                        <label className="label" htmlFor="usersname">
                          How big is your team?
                        </label>
                        <div className="radio-toolbar">
                          <input type="radio" id="option1" name="teamSize" value="1-50" />
                          <label for="option1">1 - 50</label>

                          <input type="radio" id="option2" name="teamSize" value="51-200" />
                          <label for="option2">51 - 200</label>

                          <input type="radio" id="option3" name="teamSize" value="200+" />
                          <label for="option3">201 +</label>
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-item w-100">
                        <label className="label" htmlFor="usersemail">
                          Email
                        </label>
                        <input
                          type="email"
                          className="input"
                          placeholder="Enter your email"
                          name="usersemail"
                          id="usersemail"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-item w-100">
                      <button
                        className="btn btn-lg btn-blue btn-block"
                        data-sal="slide-up"
                        data-sal-easing="ease"
                        data-sal-delay="300"
                        data-sal-duration="400"
                      >
                        Contact Sales
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Cta />
    </Layout>
  );
};

const IndexPageWithRecaptcha = () => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LcnUFciAAAAAPV3DD2sLA8YovKBy2EW8JX0Ksrx">
      <IndexPage />
    </GoogleReCaptchaProvider>
  );
};

export default IndexPageWithRecaptcha;
