import * as React from "react"
import { Link } from "gatsby"

export default function Cta() {

  return (
    <section className="bg-gradient">
              <div className="container">
                <div className="row">
                  <div className="cta-content">
                    <h4>Improve your team performance</h4>
                    <div className="cta-btn-wrapper">
                      <Link to="/contact" className="btn btn-lg btn-line"
                        data-sal="slide-up" 
                        data-sal-easing="ease"
                        data-sal-delay="200"
                        data-sal-duration="400"
                      >Request demo</Link>
                    </div>
                  </div>
                </div>
              </div>
	</section>
  )
}